import React, { Component, useState } from 'react'
import { Quicksand,Poppins } from 'next/font/google'
import Image from 'next/image';
import Link from 'next/link';
const quickSand= Quicksand({weight:"400",subsets:['latin']})
const popins= Poppins({weight:"400",subsets:['latin']})

export default class BlogCards extends Component {
constructor(props){
  super(props);
  this.state ={
    currentPage: 1,
    itemsPerPage: 6, // Adjust the number of items per page as needed
    searchTerm: '',
  }
}
handleSearchChange = (event) => {
  this.setState({ searchTerm: event.target.value });
};

filterData =()=>{
  const {BlogCardsList}=this.props;
  const {searchTerm}=this.state;
return BlogCardsList.filter(item =>
  item.courseName.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
}

render(){
 const filteredData= this.filterData();
  const { currentPage, itemsPerPage } = this.state;
  const {hideTitle} = this.props

      // Calculate the index range of items to display for the current page
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
      const totalPages = Math.ceil(filteredData.length / itemsPerPage);


  return (
   <>
   
   <section>

<div className='container'>

{/* Top Search Bar */}
{hideTitle ? null : (
  <div className='df  mdf mfdc mfac mpdt30'>
  {/* <div>
      <h6 className={`ylw-clr fs-19 mfs-16 pdl20 mpdl0 ${popins.className}`}>showing All Blogs</h6>
  </div> */}

    <div className='df fac fjse search-bar mmt10 ml229 mml0 mmt25'>

    <div className=' search-icon '>
      <Image src={"/blog-imgs/blog-cards-img/search-icon.webp"} alt='Search-Icon' width={"32"} height={"32"}/>
    </div>
      <input className={`search-input fs-16 ${popins.className}`} type='search'value={this.state.searchTerm} onChange={this.handleSearchChange}/>
     <div>
     <button className={`search-btn ylw-clr fs-16 ${quickSand.className}`}>Go</button>
     </div>
    </div>
  </div>
)}
    <div className='df fjsa fw mdf mfjc mfac gap10 pdb30'>
{currentItems.map((items,index)=>{
return(
<div key={index} className='pdt40 mpdt20'>
<div className='blog-card-box' style={{ backgroundImage: `url(${items.bg_img})`}}>

   <div className='card-inside-box '>
 <Link href={`${items.path}`}>
    <div className='blog-title'>
        <p className={`fs-19 tac pdt15 mpdt5 mfs-14 hvz ${quickSand.className}`}>{items.courseName}</p>
    </div>

     <p className={`fs-19 pdt10 mfs-12 mpdt8 pdl20 mpdl15 mpdt8 hvz ${quickSand.className}`}>{items.cnt}</p>
    
     <div className="df fjc fac pdt8">
      <p className="line-code"></p>
    </div>
    <div className='pdl20 pdt10 mpdl15 mpdtb10 df '>
    <svg className='icon-cal' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>
    <p className={`df fac pdl20 mpdl15 dim-clr mfs-12 hvz ${quickSand.className}`}>{items.date}</p>
    </div>
    </Link>
    </div> 

</div>
</div>
)})

}</div>


{filteredData.length > itemsPerPage && (
            
            <div className="df fac fjc mt100 mb88 mmt40 mmb30">
            
<button className="prev-btn" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}>
<Image src={"/course-img/pagination-icons/next-icon.svg"} alt="Next-Icon" width={"12"} height={"24"} loading="lazy"/>    
       </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <p
                key={page}
                onClick={() => this.setState({ currentPage: page })}
                className={`fs-19 df fac fjc ${currentPage === page ? 'current-page ' : ''}fs-19 page df fac fjc ${popins.className}`}
              >
                {page}
              </p>
            ))}
              <button className="next-btn" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={indexOfLastItem >= filteredData.length}>
              <Image src={"/course-img/pagination-icons/prev-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>
              </button>
            </div>
          )}


</div>


   </section>
   
<style jsx>
    {`
    .search-btn{
      width: 72px;
      height:40px;
      border-radius:30px;
      border:none;
      background-color:#FFFFFF;
      text-align: center;
      cursor:pointer;

    }

    .search-bar{
      width:400px;
      height:58px;
      border-radius: 30px;
      background-color:#FFA900;
    }
    .search-input{
      border:none;
      outline:none;
      background-color: transparent;
      width:auto;
      color:#FFFFFF;
      
    }
    .search-input::-webkit-search-cancel-button{
      display:none;
    }
      .next-btn:disabled,
      .prev-btn:disabled {
        opacity: 0.5; /* or any other styling to indicate it's disabled */
        cursor: not-allowed;
      }
                .current-page {
                
                  color: #FFA900; /* Set the text color to black or any color you prefer */
                  width:84px;
                  height:60px;
                  border-right: 1px solid #D3D3D3;
                  border-left: 1px solid #D3D3D3;
                  text-align: center;
                }
                .page {

                  width:84px;
                  height:60px;
                  border-right: 1px solid #D3D3D3;
                  border-left: 1px solid #D3D3D3;
                  text-align: center;
                  cursor: pointer;
                }
                .next-btn{
                  background-color: #FFFFFF;
                  width:84px;
                  height: 60px;
                  cursor: pointer;
                  color:#FFA900;
                  border: none;
                  font-size: 24px;
                  font-weight: 500;
                  border-radius: 0px 30px 30px 0px;
                  box-shadow: 0px 4px 16px 0px #FFA90040;
                }
                
                .prev-btn{
                  width:84px;
                  height: 60px;
                  background-color: #FFFFFF;
                  border: none;
                  cursor: pointer;
                  font-size: 24px;
                  font-weight: 500;
                  color:#FFA900;
                  border-radius: 30px 0px 0px 30px;
                  box-shadow: 0px 4px 8px 5px #FFA90040;
                }
           

   .icon-cal{
   width: 27px;
   fill: #666666;
   height: 27px;
   }
   .card-inside-box:hover {
    background-color: #FFA900; /* Replace with your desired hover background color */
    transition: background-color 0.5s ease-in-out;

  }
  .card-inside-box:hover .hvz{
    color: #FFFFFF;
    transition: color 0.5s ease-in-out;
  }
  .card-inside-box:hover .icon-cal{
    fill: #FFFFFF;
    transition: fill 0.5s ease-in-out;
  }
  .line-code {
    width: 264.36px;
    border: 1.36px solid #d3d3d3;
    background-color: #d3d3d3;

  }
    .blog-title{
        width: 185px;
        height: 54px;
        border-radius: 31.59px 0px 31.59px 0px;
        border: 1px solid #FFA900;
        background-color: #FFA900;

    }
    .blog-card-box{
     
            width: 400px;
            height: 442px;
            box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 64px;
            position: relative;
            margin-left:0px
    }
    .card-inside-box{
        width: 313px;
        height: 203px;
        border-radius: 31px;
        border: 2px solid rgba(255, 169, 0, 1);
        position: absolute;
        top:194px;
        right:43px;
        background: rgba(255, 255, 255, 1);
        cursor:pointer;
    }

    @media only screen and (max-width: 1023px){

      .search-btn{
        width: 42px;
        height:30px;
        border-radius:20px;
        border:none;
        background-color:#FFFFFF;
        text-align: center;
  
      }

      .search-bar{
        width:280px;
        height:48px;
        border-radius: 30px;
        background-color:#FFA900;
      }
      .current-page {
          
        color: #FFA900; /* Set the text color to black or any color you prefer */
        text-align: center;
      }
      .page {

        width:44px;
        height:44px;
       border:none;
        text-align: center;
        cursor: pointer;
      }
      .next-btn{
        background-color: #FFFFFF;
        width:44px;
        height: 44px;
        cursor: pointer;
        color:#FFA900;
        border: none;
        font-size: 19px;
        font-weight: 500;
        border-radius: 0px 15px 15px 0px;
        box-shadow: 0px 4px 10px 0px #FFA90040;
      
      }
      
      .prev-btn{
        width:44px;
        height: 44px;
        background-color: #FFFFFF;
        border: none;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        color:#FFA900;
        box-shadow: 0px 4px 10px 0px #FFA90040;
      }
        .blog-card-box{
     
            width: 285px;
            height: 326px;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 47px;
            position: relative;
            margin-left:0px
    }
    .card-inside-box{
        width: 231px;
        height: 53%;
        border-radius: 23px;
        border: 1.5px solid rgba(255, 169, 0, 1);
        position: absolute;
        top:143px;
        right:25px;
        background: rgba(255, 255, 255, 1);

    }
    .blog-title{
        width: 137px;
        height: 40px;
        border-radius: 23.3px 0px 23.3px 0px;
        border: 1px solid #FFA900;
        background-color: #FFA900;

    }
    .line-code {
        width: 195px;
        border: 1px solid #d3d3d3;
        background-color: #d3d3d3;
      }

    }
    
    `}  
    
</style>   
   
   </>
  )
}}
